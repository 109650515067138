import React from 'react';
import { Icons } from '../assets/imageIndex';

const TitleHeader = ({ title, image }) => {
    return (
        <section
            className="banner-header h-[350px] section-padding relative flex items-center justify-center"
        >
            {/* Background Image */}
            <img
                src={image || Icons.techBg}
                className="absolute top-0 left-0 w-full h-full object-top"
                alt="Banner"
            />

            {/* Overlay */}
            <div className="absolute h-full w-full inset-0 bg-black opacity-50"></div>

            {/* Title */}
            <div className="relative z-10 text-center" data-aos="fade-up" data-aos-duration="1500">
                <h1 className="text-4xl text-orange-600 font-bold">{title}</h1>
            </div>
        </section>
    );
};

export default TitleHeader;
