import React from 'react';
import TitleHeader from '../component/TitleHeader';

const TermsConditions = () => {
    return (
        <div className="bg-gray-100">
            <div className="bg-white shadow-md rounded-lg ">
                <TitleHeader title="Terms & Conditions" />
                <div className="text-left px-10 py-10 space-y-6">
                    <p>Please read these terms and conditions carefully before using Our Service.</p>

                    <h2 className="text-2xl font-bold">Interpretation and Definitions</h2>
                    <h3 className="text-xl font-semibold">Interpretation</h3>
                    <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

                    <h3 className="text-xl font-semibold">Definitions</h3>
                    <p>For the purposes of these Terms and Conditions:</p>
                    <ul className="list-disc ml-5 space-y-2">
                        <li>
                            <strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.
                        </li>
                        <li>
                            <strong>Country</strong> refers to: Delhi, India.
                        </li>
                        <li>
                            <strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Covantis Sarvagis Technologies, Delhi, India.
                        </li>
                        <li>
                            <strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.
                        </li>
                        <li>
                            <strong>Service</strong> refers to the Website.
                        </li>
                        <li>
                            <strong>Terms and Conditions</strong> (also referred to as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.
                        </li>
                        <li>
                            <strong>Third-party Social Media Service</strong> means any services or content (including data, information, products, or services) provided by a third-party that may be displayed, included, or made available by the Service.
                        </li>
                        <li>
                            <strong>Website</strong> refers to Covantis Sarvagis Technologies, accessible from <a href="https://covantis.in/" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">covantis.in</a>
                        </li>
                        <li>
                            <strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
                        </li>
                    </ul>

                    <h2 className="text-2xl font-bold">Acknowledgment</h2>
                    <p>These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.</p>
                    <p>Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users, and others who access or use the Service.</p>
                    <p>By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.</p>
                    <p>You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.</p>

                    <h2 className="text-2xl font-bold">Links to Other Websites</h2>
                    <p>Our Service may contain links to third-party websites or services that are not owned or controlled by the Company.</p>
                    <p>The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites or services.</p>
                    <p>We strongly advise You to read the terms and conditions and privacy policies of any third-party websites or services that You visit.</p>

                    <h2 className="text-2xl font-bold">Termination</h2>
                    <p>We may terminate or suspend Your access immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions.</p>
                    <p>Upon termination, Your right to use the Service will cease immediately.</p>

                    <h2 className="text-2xl font-bold">Limitation of Liability</h2>
                    <p>Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of these Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the Service or 100 USD if You haven't purchased anything through the Service.</p>

                    <h2 className="text-2xl font-bold">"AS IS" and "AS AVAILABLE" Disclaimer</h2>
                    <p>The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service.</p>

                    <h2 className="text-2xl font-bold">Governing Law</h2>
                    <p>The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service.</p>

                    <h2 className="text-2xl font-bold">Contact Us</h2>
                    <p>If you have any questions about these Terms and Conditions, You can contact us:</p>
                    <ul className="list-disc ml-5 space-y-2">
                        <li>By email: <a href="mailto:info@covantis.in" className="text-blue-500 hover:underline">info@covantis.in</a></li>
                        <li>By visiting this page on our website: <a href="https://covantis.in/contact" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">https://covantis.in/contact</a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default TermsConditions;
